import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {UtilitiesService} from "@orion/utilities/utilities.service";
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService  {

  tenantId$: BehaviorSubject<string>= new BehaviorSubject<string>('');
  private orionUserSubject: BehaviorSubject<User>;
  public orionUser: Observable<User>;


  constructor(private http: HttpClient, 
    private router: Router, 
    private utilitiesService: UtilitiesService,
    private keycloakService: KeycloakService) {
    this.orionUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('orionUser')));
    this.orionUser = this.orionUserSubject.asObservable();
  }

  /**
   * Angular Auth Guard pattern service call
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLogin();
  }

  checkLogin(): Observable<boolean> {
    return this.orionUser.pipe(
      map(loggedInUserData => {
        // console.log("Auth call is user available ", loggedInUserData);
        // debugger;
        if (loggedInUserData) {
          //TODO Uncomment and implement spring boot aurthorities
          //const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities);
          //if (hasAnyAuthority) {
          //return true;
          //}
          //this.router.navigate(['accessdenied']);
          if(localStorage.getItem('tenantId') == 'KE'){
            if(this.router.url.includes('user-dashboard') || this.router.url.includes('institutions') || this.router.url.includes('login') || this.router.url.includes('')){
              return true;
            } else {
              this.utilitiesService.error3Alert('Oops! we couldn\'t find the page yor are looking for.').then((result) => {
                if (result.isConfirmed) {
                  this.logout();
                }
              });
              return false;
            }
          } else {
            return true;
          }
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }

  public get orionUserValue(): User {
    return this.orionUserSubject.value;
  }


  // Get User
  getUser(userId: string):Observable<any> {
    const apiUrl = `${environment.serverUrl}`;
    return this.http.get<any>(`${apiUrl}/api/v1/users/logged-in-profile/${userId}`)
    .pipe(
        map((user) => {
        localStorage.removeItem('orionUser');
        localStorage.setItem('orionUser', JSON.stringify(user));
        this.orionUserSubject.next(user);
        return user;
        })
      )
  }

  // login(tenantId: string, email: string, password: string) {
  //   return this.http.post<any>(`${environment.serverUrl}/api/login`,
  //     {username: email, password: password})
  //     .pipe(map(user => {
  //       // login successful if there's a jwt token in the response
  //       localStorage.removeItem('orionUser');
  //       localStorage.removeItem('tenantId');
  //       localStorage.setItem('tenantId', tenantId);
  //       localStorage.setItem('orionUser', JSON.stringify(user));
  //       this.orionUserSubject.next(user);
  //       return user;
  //     }));
  // }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('roles');
    localStorage.removeItem('orionUser');
    localStorage.removeItem('tenantId');
    this.keycloakService.logout();
    this.orionUserSubject.next(null);
  }
}
