export const environment = {
  production: true,
  //  serverUrl: 'https://api.myxeno.com',
 serverUrl: 'https://bf.xn-io.com/leg',
  // nextServerUrl: 'https://3089-197-211-58-130.ngrok-free.app',

  // serverUrl: 'https://10.0.0.94:8082/leg',
  // serverUrl: 'http://192.168.0.108:8080',
  // serverUrl: 'https://ultima259.myxeno.com',
  // serverUrl: 'https://ultima-ke.xn-io.com',
  /// ==== Keycloak settings
  keycloak: {
    clientId: 'xeno-orion-client',
  //  redirectUrl: 'http://localhost:7100',
    redirectUrl: 'https://orion.ebarit.com',
    realm: 'xeno-universe-realm',
    issuer: 'https://auth.myxeno.com/'
  }
};
