import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { User } from './models/user';
import { AuthenticationService } from './services';
import { KeycloakService } from 'keycloak-angular';
import { catchError, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userExists: boolean = false;
  orionUser: User;
  selectCountry: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private keycloakService: KeycloakService) {
    authenticationService.orionUser.subscribe((x) => {
      this.orionUser = x;
      this.userExists = true;
    });
  }

  async ngOnInit() {

    let loggedInUser = JSON.parse(localStorage.getItem("orionUser"))
    const validateUser = this.keycloakService.isLoggedIn();
    // debugger;

    if (validateUser && loggedInUser == null) {
      // Get token
      const acessToken = await this.keycloakService.getToken();
      localStorage.removeItem('tenantId');
      localStorage.setItem('accessToken', acessToken)
      localStorage.setItem('roles', JSON.stringify(this.keycloakService.getKeycloakInstance().realmAccess.roles))
      // debugger;

      const userProfile = await this.keycloakService.loadUserProfile();
      const tenantId = userProfile.attributes['tenants'][0]
      localStorage.setItem('tenantId', tenantId);
      const uid = userProfile.id;
      this.getUserDetails(uid)
    }

    if (loggedInUser) {
      this.router.navigate(['/user-dashboard'])
    }

    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0);
    //   document.getElementsByTagName('body')[0].classList.remove('disable-scroll');
    // });
  }

  getUserDetails(uid: string) {
    this.authenticationService
      .getUser(uid).subscribe((r) => {
        if (r) {
          debugger;
          const roles = JSON.stringify(this.keycloakService.getKeycloakInstance().realmAccess.roles);
          if (roles.includes('ROLE_XENO_GROUP')) {
            this.selectCountry = true;
            debugger;
          } else {
            this.router.navigate(['/user-dashboard'])
          }

        } else {
          alert('User not found')
        }
      })
  }

  // // Get user details
  // async getUserDetails(userId: string){
  //   this.authenticationService
  //       .getUser(userId)
  // }
}
// function jwtDecode(acessToken: string) {
//   throw new Error('Function not implemented.');
// }

